import { HeadFC } from "gatsby";
import React, { useEffect } from "react";
import CookiesConsentNotice from "../../components/cookies-consent/cookies-consent";
import SomethingElseCta from "../../components/ctas/something-else-cta";
import Footer from "../../components/footer/footer";
import Navigation from "../../components/navigation/navigation";
import PackageEnquiry from "../../components/package-enquiry/package-enquiry";
import SEO from "../../components/seo/seo";
import StylesComponent from "../../components/styles/styles-component";

const PrivateYachtExperience: React.FC = (): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         <CookiesConsentNotice/>

         <div className="event-hero-outer-container" style={{ backgroundImage: `url(https://images.ctfassets.net/welt89o8b674/6pmy5PeQfxGx34F5uFTmit/6faf33ec130dde4210a9089139ac9679/private-yacht-experience.jpeg)`}}>
            <div className="event-hero-container-overlay">
               <div className="event-hero-text-container container-width">
                  <h1>Private yacht</h1>
                  <p className="secondary-text">Bespoke luxury experiences</p>

                  <br/>

                  <a href="#package-enquiry" className="standard-button orange">See availability</a>
               </div>
            </div>
         </div>

         <div className="standard-event-outer-container">
            <div className="standard-event-inner-container container-width">
               <h2>Experience details</h2>

               <div className="event-details-container">
                  <div className="event-details-item">
                     <span>🗓</span>
                     <p>Enquire for availability</p>
                  </div>

                  <div className="event-details-item">
                     <span>🌍</span>
                     <p>Worldwide</p>
                  </div>

                  <div className="event-details-item">
                     <span>🛥</span>
                     <p>14+ models</p>
                  </div>

                  <div className="event-details-item">
                     <span>💵</span>
                     <p>Pricing options</p>
                  </div>
               </div>

               <div className="event-summary-wrapper">
                  <p><strong>The perfect environment for entertaining and travelling in style, a private yacht affords the discerning traveller seeking a memorable and exclusive exprience with VIP access to the open seas. Make the most of your travels with comfort and views like no other.</strong></p>

                  <p>With ample space for entertaining guests, yachts acquired through Prestige VIP can be fully equipped with fully functional kitchens, comfortable living quarters and state-of-the-art entertainment systems. Whether you're looking to host a formal gathering or simply enjoy a relaxing day at sea, our yachts are the perfect setting.</p>

                  <p>From the moment you step aboard, you’ll be surrounded by breathtaking beauty and first-class amenities. With a focus on detail and comfort, every aspect of these yachts have been designed to ensure your stay is both memorable and unforgettable.</p>

                  <p>So, if you’re looking for an exclusive and unforgettable experience, look no further. Contact us today to start planning your dream yacht rental and set sail on an adventure unlike any other.</p>
               </div>
            </div>
         </div>

         <PackageEnquiry
            eventName="Private yacht availability"
            eventImage="https://images.ctfassets.net/welt89o8b674/6i7NGowEaJjwUigtQ0JOHj/c918fe1c5b4cb10dc49bd974dd783abe/monaco-marina.jpg"
         />

         <SomethingElseCta/>

         <Footer/>
      </React.Fragment>
   )
}

export const Head: HeadFC = () => (
   <SEO
      title={`Private yacht | Prestige VIP`}
      metaDescription={`Experience travel in the most luxurious way with your own private yacht. International availabilty with a variety of options.`}
      slug="/experiences/yacht"
   />
)

export default PrivateYachtExperience